import React from 'react';

import { ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import {
  Button,
  ConfigProvider,
  Empty,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { TProductEntitlement } from 'src/api/types/sku.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import StatusTag from 'src/components/StatusTags/StatusTags';
import { namiDarkOrange } from 'src/variables';

import { DynamicallySizedParagraph } from '../../campaigns/CampaignsTable';

type ProductEntitlementTableProps = {
  isLoading: boolean;
  entitlements: TProductEntitlement[] | undefined;
};

export default function ProductEntitlementTable({
  isLoading,
  entitlements,
}: ProductEntitlementTableProps) {
  const history = useHistory();
  const entitlementColumns: ColumnsType<TProductEntitlement> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, entitlement: TProductEntitlement) => (
        <Tooltip title={entitlement.name} mouseEnterDelay={0.3}>
          <DynamicallySizedParagraph
            ellipsis={true}
            onClick={() => history.push(`/entitlements/${entitlement.id}/`)}
          >
            {entitlement.name}
          </DynamicallySizedParagraph>
        </Tooltip>
      ),
      fixed: 'left',
      width: 200,
    },
    {
      title: 'Reference ID',
      dataIndex: 'entitlement_ref_id',
      key: 'entitlement_ref_id',
      responsive: ['md'],
      render: (entitlement_ref_id: string) => (
        <MonospaceText>{entitlement_ref_id}</MonospaceText>
      ),
      width: 200,
    },
    {
      title: 'Associated Date',
      dataIndex: 'associated_date',
      key: 'associated_date',
      render: (associated_date: string) => {
        return (
          <Tooltip title={moment(associated_date).format('YYYY-MM-DD h:mm A')}>
            <span>{moment(associated_date).fromNow()}</span>
          </Tooltip>
        );
      },
      width: 150,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any, record: TProductEntitlement) => (
        <IconActionButton
          type="text"
          icon={<EyeOutlined style={{ fontSize: 14 }} />}
          href={'/entitlements/' + record.id + '/'}
          size="small"
        >
          View
        </IconActionButton>
      ),
      width: 100,
    },
  ];

  return (
    <>
      <Typography.Title level={5} style={{ marginTop: '48px' }}>
        Associated Entitlements
      </Typography.Title>
      <Typography.Paragraph>
        Associated entitlements are granted when this product SKU is purchased
        on device.
      </Typography.Paragraph>
      <ConfigProvider
        renderEmpty={() => (
          <Empty imageStyle={{ display: 'none' }} description={<></>}>
            <StatusTag color="yellow">
              <Button
                type="link"
                size="small"
                style={{ color: namiDarkOrange, fontSize: '1em' }}
                onClick={() => history.push('/entitlements/')}
                icon={<ExclamationCircleOutlined />}
              >
                0 Configured Entitlements
              </Button>
            </StatusTag>
          </Empty>
        )}
      >
        <Table
          dataSource={entitlements}
          columns={entitlementColumns}
          pagination={false}
          loading={isLoading}
          bordered={true}
          size="small"
          rowKey={'id'}
          scroll={{ x: 1200 }}
          className="horizontalScrollTable"
        />
      </ConfigProvider>
    </>
  );
}

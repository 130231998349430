import React from 'react';

import { Space, Typography } from 'antd';
import styled from 'styled-components';

type ObjectTitleProps = {
  icon: JSX.Element;
  title: string;
  tags: JSX.Element[];
};

const Title = styled(Typography.Text)`
  font-size: 18px;
  font-weight: 500;
`;

export default function ObjectTitle({ icon, title, tags }: ObjectTitleProps) {
  return (
    <Space direction="horizontal" size={12}>
      {icon}
      <Title>{title}</Title>
      {(tags || []).map((tag) => tag)}
    </Space>
  );
}

import React from 'react';

import { ConfigProvider, Empty, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import {
  AppleProductOfferModeReadable,
  TAppleProductOffer,
  TAppleProductOfferMode,
} from 'src/api/types/sku.types';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import { useApplePromotionalOffersForProductQuery } from 'src/hooks/queries/product.hooks';
import {
  convertIso8601DurationToPeriod,
  convertIso8601DurationToReadable,
} from 'src/utils/date';
import { namiDarkGray } from 'src/variables';

export default function AppleOffersTable() {
  const productId = useParams<{ productID: string }>().productID;
  const appleOffersForProductQuery =
    useApplePromotionalOffersForProductQuery(productId);

  const columns: ColumnsType<TAppleProductOffer> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'offer_mode',
      key: 'offer_mode',
      render: (offer_mode: TAppleProductOfferMode) => (
        <span>{AppleProductOfferModeReadable[offer_mode]}</span>
      ),
    },
    {
      title: 'Duration',
      key: 'duration',
      render: (offer: TAppleProductOffer) => {
        if (offer.offer_mode === 'SubscriptionOfferMode.PAY_AS_YOU_GO') {
          const periodNumber = offer.number_of_periods;
          const period = convertIso8601DurationToPeriod(offer.duration);
          return (
            <span>{`${periodNumber} ${period}${
              periodNumber > 0 ? 's' : ''
            }`}</span>
          );
        }
        return <span>{convertIso8601DurationToReadable(offer.duration)}</span>;
      },
    },
    {
      title: 'Offer Code',
      dataIndex: 'offer_code',
      key: 'offer_code',
      render: (offer_code: string) => (
        <MonospaceText>{offer_code}</MonospaceText>
      ),
    },
    {
      title: 'First Sync Date',
      dataIndex: 'created_date',
      key: 'created_date',
      render: (created_date: string) => (
        <Tooltip title={moment(created_date).format('YYYY-MM-DD h:mm A')}>
          <span>{moment(created_date).fromNow()}</span>
        </Tooltip>
      ),
      responsive: ['lg'],
    },
  ];

  return (
    <>
      <Typography.Title level={5} style={{ marginTop: '48px' }}>
        Promotional Offers
      </Typography.Title>
      <Typography.Paragraph>
        Promotional offers provide a discounted price for a specific duration
        for existing and former subscribers. You can configure promotional
        offers in{' '}
        <a
          href="https://appstoreconnect.apple.com/"
          target="_blank"
          rel="noreferrer"
        >
          App Store Connect.
        </a>
      </Typography.Paragraph>
      <ConfigProvider
        renderEmpty={() => (
          <Empty imageStyle={{ display: 'none' }} description={<></>}>
            <span style={{ color: namiDarkGray, fontStyle: 'italic' }}>
              No promotional offers found
            </span>
          </Empty>
        )}
      >
        <Table
          dataSource={appleOffersForProductQuery.data?.results || []}
          columns={columns}
          pagination={false}
          loading={appleOffersForProductQuery.isLoading}
          bordered={true}
          size="small"
          rowKey="id"
        />
      </ConfigProvider>
    </>
  );
}

import React, { useMemo } from 'react';

import {
  CloseOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  ConfigProvider,
  Empty,
  Image,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { TEntitlementProduct } from 'src/api/types/entitlement.types';
import { TProduct } from 'src/api/types/sku.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import StatusTag from 'src/components/StatusTags/StatusTags';
import { platformLogo } from 'src/services/helpers';
import styled from 'styled-components';

import { useAppContext, useBooleanState } from '../../../hooks';
import {
  useEntitlementQuery,
  useUpdateProductAssociationMutation,
} from '../../../hooks/queries/entitlement.hooks';
import { useProductsQuery } from '../../../hooks/queries/product.hooks';
import { DynamicallySizedParagraph } from '../campaigns/CampaignsTable';
import EntitlementProductAssociationModal from './EntitlementProductAssociationModal';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function EntitlementProductTable() {
  const history = useHistory();
  const { userHasEntitlement } = useAppContext();
  const productsQuery = useProductsQuery({ pageSize: 2000, archived: false });
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const entitlementId = useParams<{ entitlementID: string }>().entitlementID;
  const entitlementQuery = useEntitlementQuery(entitlementId);
  const updateProductsMutation = useUpdateProductAssociationMutation(
    entitlementId,
    { onSuccess: closeModal }
  );

  const productIdMap = useMemo(() => {
    return (productsQuery.data?.results || []).reduce((output, product) => {
      return { ...output, [product.id]: product };
    }, {} as Record<string, TProduct>);
  }, [productsQuery.data?.results]);

  const availableProducts = useMemo(() => {
    const associatedIds = new Set(
      entitlementQuery.data?.skus.map(({ id }) => id) || []
    );
    return (productsQuery.data?.results || []).filter(
      (product) => !associatedIds.has(product.id)
    );
  }, [entitlementQuery.data?.skus, productsQuery.data?.results]);

  const columns: ColumnsType<TEntitlementProduct> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, product: TEntitlementProduct) => (
        <Tooltip title={product.name} mouseEnterDelay={0.3}>
          <DynamicallySizedParagraph
            ellipsis={true}
            onClick={() => history.push(`/products/${product.id}/`)}
          >
            {product.name}
          </DynamicallySizedParagraph>
        </Tooltip>
      ),
      fixed: 'left',
      width: 250,
    },
    {
      title: 'SKU ID',
      key: 'sku_ref_id',
      render: (_, product) => {
        if (!(product.id in productIdMap))
          return <Skeleton paragraph={false} active title={{ width: '90%' }} />;
        return (
          <MonospaceText>{productIdMap[product.id].sku_ref_id}</MonospaceText>
        );
      },
      width: 250,
    },
    {
      title: 'Platform',
      key: 'platform',
      responsive: ['lg'],
      render: (_, product) => {
        if (!(product.id in productIdMap))
          return <Skeleton paragraph={false} active title={{ width: '90%' }} />;
        const { platform_name, platform_type } = productIdMap[product.id];
        return (
          <Space>
            <Image
              src={platformLogo(platform_type)}
              height={15}
              width={15}
              preview={false}
            />
            {platform_name}
          </Space>
        );
      },
      width: 200,
    },
    {
      title: 'Associated Date',
      key: 'associated_date',
      render: ({ associated_date }: TEntitlementProduct) => {
        return (
          <Tooltip title={moment(associated_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'default' }}>
              {moment(associated_date).fromNow()}
            </span>
          </Tooltip>
        );
      },
      width: 150,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any, product: TEntitlementProduct) => (
        <Space direction="horizontal">
          <IconActionButton
            type="text"
            icon={<EyeOutlined style={{ fontSize: 14 }} />}
            onClick={() => history.push(`/products/${product.id}/`)}
            size="small"
          >
            View
          </IconActionButton>
          <Popconfirm
            title="Are you sure? If you detach, users who buy this product won't be granted this permission."
            cancelText="No"
            okText="Yes, detach"
            onConfirm={() => {
              const data = { id: product.id, associated: false };
              updateProductsMutation.mutate([data]);
            }}
          >
            <IconActionButton
              type="text"
              icon={<CloseOutlined style={{ fontSize: 14 }} />}
              disabled={!userHasEntitlement('app.entitlement.update')}
              size="small"
            >
              Detach
            </IconActionButton>
          </Popconfirm>
        </Space>
      ),
      width: 200,
    },
  ];

  return (
    <>
      <Header>
        <div>
          <Typography.Title level={5} style={{ marginTop: '48px' }}>
            Associated Products
          </Typography.Title>
          <Typography.Paragraph>
            Associated products grant this entitlement when they are purchased
            on-device.
          </Typography.Paragraph>
        </div>
        <Button
          icon={<PlusOutlined />}
          disabled={
            availableProducts.length === 0 ||
            !userHasEntitlement('app.entitlement.update')
          }
          onClick={openModal}
          loading={productsQuery.isFetching}
        >
          Associate Product SKUs
        </Button>
      </Header>
      <ConfigProvider
        renderEmpty={() => (
          <Empty imageStyle={{ display: 'none' }} description={<></>}>
            <StatusTag color="yellow" icon={<ExclamationCircleOutlined />}>
              0 Associated Products
            </StatusTag>
          </Empty>
        )}
      >
        <Table
          dataSource={entitlementQuery.data?.skus || []}
          pagination={false}
          loading={entitlementQuery.isFetching}
          bordered={true}
          rowKey={'id'}
          size="small"
          columns={columns}
          scroll={{ x: 1300 }}
          className="horizontalScrollTable"
        />
      </ConfigProvider>
      <EntitlementProductAssociationModal
        isOpen={isModalOpen}
        isLoading={updateProductsMutation.isLoading}
        products={availableProducts}
        onOk={updateProductsMutation.mutate}
        onCancel={closeModal}
      />
    </>
  );
}

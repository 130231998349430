import React from 'react';

import { Button } from 'antd';
import { useDownloadInitialConfigMutationVersion31 } from 'src/hooks/queries/platform.hooks';

type ExportInitialConfigButtonProps = {
  loading: boolean;
  platformId: string;
  disabled: boolean;
};

export default function ExportInitialConfigButton({
  loading,
  platformId,
  disabled,
}: ExportInitialConfigButtonProps) {
  const downloadConfigMutationV31 =
    useDownloadInitialConfigMutationVersion31(platformId);

  return (
    <Button
      disabled={disabled}
      loading={loading || downloadConfigMutationV31.isLoading}
      onClick={() => downloadConfigMutationV31.mutate()}
    >
      Export initial config
    </Button>
  );
}

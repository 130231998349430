import React from 'react';

import {
  CheckCircleTwoTone,
  DoNotDisturbOnTwoTone,
  ModeTwoTone,
} from '@mui/icons-material';
import { namiCoral, namiDarkGray, namiGreen } from 'src/variables';

export type TProductEntitlement = {
  id: string;
  name: string;
  entitlement_ref_id: string;
  created_date: string;
  associated_date: string;
};

export type TLanguage = { language: string; language_humanized: string };

export type TSkuTranslation = {
  id: string;
  display_text: string;
  sub_display_text: string;
};

export type TSkuTranslationFull = TLanguage & TSkuTranslation;

export type TProductPayload = {
  name: string;
  platform: string;
  sku_type: string;
  sku_ref_id: string;
  standard_duration?: string;
  free_trial_duration?: string;
  grace_period_duration?: string;
  archived: boolean;
};

export type TProduct = TProductPayload & {
  id: string;
  config_warnings: Record<string, any>;
  delete_warnings: Record<string, any>;
  entitlements: TProductEntitlement[];
  languages: string[];
  platform_name: string;
  platform_type: string;
  translations: { [language: string]: TSkuTranslation };
  created_date: string;
  updated_date: string;
  auto_created: boolean;
  platform_synced: null | string;
};

type TOfferSku = {
  id: string;
  name: string;
  sku_ref_id: string;
};

export type TAppleProductOffer = {
  id: string;
  created_date: string;
  created_date_humanized: string;
  apple_id: string;
  duration: string;
  name: string;
  number_of_periods: number;
  offer_code: string;
  offer_mode: TAppleProductOfferMode;
  product_sku: TOfferSku;
};

export type TAppleProductOfferMode =
  | 'SubscriptionOfferMode.PAY_UP_FRONT'
  | 'SubscriptionOfferMode.PAY_AS_YOU_GO'
  | 'SubscriptionOfferMode.FREE_TRIAL';

export const AppleProductOfferModeReadable: Record<
  TAppleProductOfferMode,
  string
> = {
  'SubscriptionOfferMode.FREE_TRIAL': 'Free Trial',
  'SubscriptionOfferMode.PAY_AS_YOU_GO': 'Pay as you go',
  'SubscriptionOfferMode.PAY_UP_FRONT': 'Pay up front',
};

export type TGoogleProductOffer = {
  id: string;
  created_date: string;
  created_date_humanized: string;
  package_name: string;
  product_id: string;
  base_plan_id: string;
  offer_id: string;
  state: TGoogleProductState;
  product_sku: TOfferSku;
  phases: TGoogleProductOfferPhase[];
};

export type TGoogleProductOfferPhase = {
  duration: string;
  recurrenceCount: number;
  type: TGoogleProductOfferMode;
};

export type TGoogleProductOfferMode =
  | 'free trial'
  | 'single payment'
  | 'discounted recurring payment';

export type TGoogleProductState =
  | 'STATE_UNSPECIFIED'
  | 'DRAFT'
  | 'ACTIVE'
  | 'INACTIVE';

export const GoogleProductStateReadable: Record<TGoogleProductState, string> = {
  ACTIVE: 'Active',
  DRAFT: 'In Draft',
  INACTIVE: 'Inactive',
  STATE_UNSPECIFIED: 'Unknown',
};

export const GoogleProductStateIcon: Record<TGoogleProductState, JSX.Element> =
  {
    INACTIVE: (
      <DoNotDisturbOnTwoTone
        style={{
          color: namiCoral,
          fontSize: 18,
        }}
      />
    ),
    ACTIVE: (
      <CheckCircleTwoTone
        style={{
          color: namiGreen,
          fontSize: 18,
        }}
      />
    ),
    STATE_UNSPECIFIED: <></>,
    DRAFT: <ModeTwoTone style={{ color: namiDarkGray, fontSize: 16 }} />,
  };

import React from 'react';

import { Tag } from 'antd';
import {
  namiDarkGray,
  namiDarkGreen,
  namiDarkOrange,
  namiDarkRed,
  namiPrimaryBlue,
} from 'src/variables';
import styled, { css } from 'styled-components';

export type ColorOptions = 'green' | 'blue' | 'yellow' | 'red' | 'gray';
type StatusTagProps = {
  color: ColorOptions;
  icon?: JSX.Element;
  monospace?: boolean;
  children: React.ReactNode;
};

const StyledTag = styled(Tag)<{ color: ColorOptions }>`
  ${({ color }) => {
    if (color === 'blue') {
      return css`
        background: #e6f7ff;
        border-color: rgba(19, 116, 222, 0.5);
        color: ${namiPrimaryBlue};
      `;
    } else if (color === 'green') {
      return css`
        background: #f6ffed;
        border-color: rgba(102, 204, 153, 0.5);
        color: ${namiDarkGreen};
      `;
    } else if (color === 'yellow') {
      return css`
        background: #fffbe6;
        border-color: rgba(173, 104, 0, 0.5);
        color: ${namiDarkOrange};
      `;
    } else if (color === 'red') {
      return css`
        background: #fff1f0;
        border: #d83a5880;
        color: ${namiDarkRed};
      `;
    }
    return css`
      background: #fafafa !important;
      border-color: rgba(76, 84, 90, 0.5);
      color: ${namiDarkGray};
    `;
  }}
`;

export default function StatusTag({
  color,
  children,
  icon,
  monospace,
}: StatusTagProps) {
  return (
    <StyledTag color={color} icon={icon}>
      {children}
    </StyledTag>
  );
}

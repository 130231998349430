import { client } from './clients';
import { TApiOptions, TListResult } from './types/main.types';
import { TProductGroup } from './types/paywall.types';
import {
  TAppleProductOffer,
  TGoogleProductOffer,
  TProduct,
  TProductPayload,
  TSkuTranslation,
  TSkuTranslationFull,
} from './types/sku.types';
import {
  buildURLParams,
  extractErrorData,
  extractListResponseData,
  extractResponseData,
} from './utils';

const products = {
  getProductGroups: (
    paywallId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TProductGroup[]> => {
    const params = buildURLParams({ pageSize: 50, ...apiOptions });
    return client
      .get(`/private/product_groups/?paywall_id=${paywallId}&${params}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  createProductGroup: (
    data: Omit<TProductGroup, 'id'>
  ): Promise<TProductGroup> =>
    client
      .post('/private/product_groups/', data)
      .then(extractResponseData)
      .catch(extractErrorData),
  updateProductGroup: (
    productGroupId: string,
    data: Omit<TProductGroup, 'id'>
  ): Promise<TProductGroup> =>
    client
      .patch(`/private/product_groups/${productGroupId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData),
  deleteProductGroup: (productGroupId: string): Promise<void> =>
    client
      .delete(`/private/product_groups/${productGroupId}/`)
      .then(extractResponseData)
      .catch(extractErrorData),
  getProduct: (productId: string): Promise<TProduct> => {
    return client
      .get(`/private/skus/${productId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getProducts: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<TProduct>> => {
    const params = buildURLParams({ pageSize: 50, ...apiOptions, appId });
    return client
      .get(`/private/skus/?${params}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  createProduct: (data: TProductPayload): Promise<TProduct> => {
    return client
      .post('/private/skus/', { ...data, entitlements: [] })
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  patchProduct: (
    productId: string,
    data: Partial<TProductPayload>
  ): Promise<TProduct> => {
    return client
      .patch(`/private/skus/${productId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updateProduct: (productId: string, data: TProduct): Promise<TProduct> => {
    return client
      .put(`/private/skus/${productId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  deleteProduct: (
    productId: string
  ): Promise<{ delete_warnings?: { is_in_use_by_paywalls?: string[] } }> => {
    return client
      .delete(`/private/skus/${productId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getProductTranslations: (
    productId: string
  ): Promise<TSkuTranslationFull[]> => {
    return client
      .get(`/private/skus/${productId}/translations/`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  addProductTranslation: (
    productId: string,
    language: string
  ): Promise<TSkuTranslationFull> => {
    return client
      .post(`/private/skus/${productId}/translations/`, { language })
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updateProductTranslation: (
    productId: string,
    translationId: string,
    data: TSkuTranslation
  ): Promise<TSkuTranslation> => {
    return client
      .put(`/private/skus/${productId}/translations/${translationId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  deleteProductTranslation: (
    productId: string,
    translationId: string
  ): Promise<void> => {
    return client
      .delete(`/private/skus/${productId}/translations/${translationId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getAllApplePromotionalOffers: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TAppleProductOffer[]> => {
    return client
      .get(`/private/apple_promotional_offer/`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  getApplePromotionalOfferForProduct: (
    productID: string
  ): Promise<TListResult<TAppleProductOffer>> => {
    return client
      .get(`/private/apple_promotional_offer/?product_sku_id=${productID}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getAllGoogleOffers: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TGoogleProductOffer[]> => {
    return client
      .get(`/private/google_subscription_offer/`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  getGoogleOfferForProduct: (
    productID: string
  ): Promise<TListResult<TGoogleProductOffer>> => {
    return client
      .get(`/private/google_subscription_offer/?product_sku_id=${productID}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};

export default products;

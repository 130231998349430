import React from 'react';

import {
  CheckCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useHistory } from 'react-router-dom';
import { TEntitlement } from 'src/api/types/entitlement.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import EmptyState from 'src/components/EmptyState/EmptyState';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import StatusTag from 'src/components/StatusTags/StatusTags';

import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import Responsive from '../../../components/Responsive/Responsive';
import { useAppContext, useBooleanState } from '../../../hooks';
import { useApiOptions } from '../../../hooks/queries/apiOptions.hooks';
import { useEntitlementsQuery } from '../../../hooks/queries/entitlement.hooks';
import { DynamicallySizedParagraph } from '../campaigns/CampaignsTable';
import NewEntitlementModal from './NewEntitlementModal';

export default function EntitlementsPage() {
  const history = useHistory();
  const appContext = useAppContext();
  const [apiOptions, updateApiOptions] = useApiOptions({ pageSize: 20 });
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const entitlementsQuery = useEntitlementsQuery(apiOptions);
  const canCreate = appContext.userHasEntitlement('app.entitlement.create');

  const dataSource = (entitlementsQuery.data?.results || []).map(
    (entitlement) => ({
      key: entitlement.id,
      ...entitlement,
    })
  );

  const COLUMNS: ColumnsType<TEntitlement & { key: string }> = [
    {
      title: <Typography.Text strong>Name</Typography.Text>,
      ellipsis: true,
      render: (entitlement: TEntitlement) => (
        <Tooltip title={entitlement.name} mouseEnterDelay={0.3}>
          <DynamicallySizedParagraph
            ellipsis={true}
            onClick={() => history.push(`/entitlements/${entitlement.id}/`)}
          >
            {entitlement.name}
          </DynamicallySizedParagraph>
        </Tooltip>
      ),
      width: 250,
      fixed: 'left',
    },
    {
      title: <Typography.Text strong>ID</Typography.Text>,
      dataIndex: 'entitlement_ref_id',
      ellipsis: true,
      render: (entitlement_ref_id: string) => (
        <Tooltip title={entitlement_ref_id}>
          <Space>
            <MonospaceText>{entitlement_ref_id}</MonospaceText>
          </Space>
        </Tooltip>
      ),
      width: 200,
    },
    {
      title: <Typography.Text strong>Product SKUs</Typography.Text>,
      ellipsis: false,
      key: 'sku_count',
      width: 150,
      render: (entitlement: TEntitlement) => (
        <Space>
          {entitlement.sku_count > 0 ? (
            <StatusTag color="green" icon={<CheckCircleOutlined />}>
              {`${entitlement.sku_count} ${
                entitlement.sku_count > 1 ? ' Product SKUs' : ' Product SKU'
              }`}
            </StatusTag>
          ) : (
            <StatusTag color="yellow" icon={<ExclamationCircleOutlined />}>
              0 Product SKUs
            </StatusTag>
          )}
        </Space>
      ),
    },
    {
      title: <Typography.Text strong>Actions</Typography.Text>,
      ellipsis: true,
      render: (entitlement: TEntitlement) => (
        <IconActionButton
          type="text"
          size="small"
          icon={<EditOutlined style={{ fontSize: '13px' }} />}
          disabled={!appContext.userHasEntitlement('app.entitlement.get')}
          onClick={() => history.push(`/entitlements/${entitlement.id}/`)}
        >
          Edit
        </IconActionButton>
      ),
      width: 175,
    },
  ];

  const isLoading = entitlementsQuery.isFetching;
  let children;
  if (isLoading) {
    children = <Loading />;
  } else if (!entitlementsQuery.data?.results.length) {
    children = (
      <EmptyState
        title="Add your first entitlement."
        link="https://learn.namiml.com/public-docs/subscription-management/entitlements"
        linkText="Learn more about entitlements."
        icon="entitlement"
        iconSize="large"
      >
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="intercom-addEntitlement"
          onClick={openModal}
          disabled={!canCreate}
        >
          Add Entitlement
        </Button>
      </EmptyState>
    );
  } else {
    children = (
      <>
        <Row justify="start" gutter={[24, 24]}>
          <Col xs={24} style={{ paddingBottom: 12 }}>
            <Responsive size="mdUp">
              <Button
                type="primary"
                className="intercom-addEntitlement"
                disabled={!canCreate}
                onClick={openModal}
                icon={<PlusOutlined />}
                style={{ float: 'right' }}
              >
                Add Entitlement
              </Button>
            </Responsive>
            <Responsive size="mdDown">
              <Button
                type="primary"
                className="intercom-addEntitlement"
                disabled={!canCreate}
                onClick={openModal}
                icon={<PlusOutlined />}
                style={{ float: 'right' }}
              >
                Add
              </Button>
            </Responsive>
          </Col>
        </Row>
        <Table
          loading={isLoading}
          dataSource={dataSource}
          columns={COLUMNS}
          scroll={{ x: 1000 }}
          pagination={{
            total: entitlementsQuery.data.count,
            pageSize: apiOptions.pageSize,
            onChange: (page, pageSize) => updateApiOptions({ page, pageSize }),
            current: entitlementsQuery.data.page_number,
            showSizeChanger: false,
            style: { paddingBottom: 72 },
            showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
          }}
          onRow={(record) => ({
            style: { cursor: 'pointer' },
            onClick: () => history.push(`/entitlements/${record.id}/`),
          })}
          style={{ cursor: 'default' }}
        />
      </>
    );
  }

  return (
    <Page title="Entitlements">
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 12 }}>
          <Breadcrumb items={[{ name: 'Entitlements' }]} />
        </Col>
      </Row>
      {children}
      <NewEntitlementModal isOpen={isModalOpen} onClose={closeModal} />
    </Page>
  );
}

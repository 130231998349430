enum QueryKeys {
  apps = 'apps',
  products = 'products',
  productTranslations = 'productTranslations',
  applePromotionalProductOffers = 'applePromotionalProductOffers',
  googleProductOffers = 'googleProductOffers',
  platforms = 'platforms',
  paywalls = 'paywalls',
  entitlements = 'entitlements',
  orgSecrets = 'orgSecrets',
  templates = 'templates',
  orgTemplates = 'orgTemplates',
  templateIcons = 'templateIcons',
  fonts = 'fonts',
  orgFonts = 'orgFonts',
  campaigns = 'campaigns',
  campaignLabels = 'campaignLabels',
  campaignLabelTags = 'campaignLabelTags',
  campaignRules = 'campaignRules',
  campaignRuleTags = 'campaignRuleTags',
  campaignSegments = 'campaignSegments',
  legacyCampaigns = 'legacyCampaigns',
  integrationsMetadata = 'integrationsMetadata',
  streams = 'streams',
  cdpIntegrations = 'cdpIntegrations',
  cdpIntegrationTokens = 'cdpIntegrationTokens',
  orgMembers = 'orgMembers',
  orgRoles = 'orgRoles',
  cdpAudiences = 'cdpAudiences',
  appCDPIntegrations = 'appCDPIntegrations',
  cdpIntegrationIdentityScope = 'cdpIntegrationIdentityScope',
  cdpIntegrationLinks = 'cdpIntegrationLinks',
  platformAllowedOrigins = 'platformAllowedOrigins',
  eventsLog = 'eventsLog',
  devices = 'devices',
  deviceCampaignRules = 'deviceCampaignRules',
  devicePaywalls = 'devicePaywalls',
  customers = 'customers',
  customerPurchases = 'customerPurchases',
  purchaseEvents = 'purchaseEvents',
  outboundEvents = 'outboundEvents',
}

export default QueryKeys;

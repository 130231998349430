import React, { useState } from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { SyncRounded } from '@mui/icons-material';
import { Button, notification, Space } from 'antd';
import api from 'src/api';
import styled from 'styled-components';

import { getRelativeTimeString } from '../utils/date';

type SyncSectionProps = {
  label: string;
  syncDate: Date | null;
  syncLabelPrefix: string;
  displayNonSynced?: boolean;
  showButton?: boolean;
  platformId?: string;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: end;
`;

export default function SyncStatus({
  label,
  syncDate,
  syncLabelPrefix,
  displayNonSynced = false,
  showButton = false,
  platformId,
}: SyncSectionProps) {
  const [justSynced, setJustSynced] = useState<boolean>(false);
  if (!syncDate && !displayNonSynced) return null;
  if (!syncDate) {
    return (
      <Wrapper>
        <Space>
          <WarningOutlined />
          <span>Not Synced</span>
        </Space>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {showButton && platformId ? (
        <Button
          style={{ marginBottom: 2 }}
          icon={<SyncRounded style={{ fontSize: 16 }} />}
          onClick={performPlatformSync}
          disabled={justSynced}
        >
          {label}
        </Button>
      ) : (
        <Space>
          <SyncRounded style={{ fontSize: 16 }} />
          <span>{label}</span>
        </Space>
      )}
      <small>
        {syncLabelPrefix} {getRelativeTimeString(syncDate)}
      </small>
    </Wrapper>
  );

  function performPlatformSync() {
    if (platformId)
      api.syncPlatform(platformId).then(() => {
        setJustSynced(true);
        notification.success({
          message: 'Sync Started',
          description:
            'The platform sync has started. Depending on how many products you have configured, the sync could take 10-60 minutes to complete.',
        });
      });
  }
}

import {
  AppleProductOfferModeReadable,
  TAppleProductOffer,
  TGoogleProductOffer,
  TGoogleProductOfferPhase,
} from 'src/api/types/sku.types';

import {
  convertIso8601DurationToPeriod,
  convertIso8601DurationToReadable,
} from './date';
import { toReadable } from './string';

export function getDurationForAppleOffer(offer: TAppleProductOffer): string {
  if (offer.offer_mode === 'SubscriptionOfferMode.PAY_AS_YOU_GO') {
    const periodNumber = offer.number_of_periods;
    const period = convertIso8601DurationToPeriod(offer.duration);
    return `${periodNumber} ${period}${periodNumber > 0 ? 's' : ''}`;
  }
  return convertIso8601DurationToReadable(offer.duration);
}
export function getDescriptionForAppleOffer(offer: TAppleProductOffer): string {
  return `${offer.name} • ${
    AppleProductOfferModeReadable[offer.offer_mode]
  } for ${getDurationForAppleOffer(offer)}`;
}
export function getDescriptionForGoogleOffer(
  offer: TGoogleProductOffer
): string {
  let phasesDescription = '';
  if (offer.phases.length === 1)
    phasesDescription = getDescriptionForGoogleOfferPhase(offer.phases[0]);
  if (offer.phases.length > 1) {
    const phase1Description =
      offer.phases[0].type === 'free trial'
        ? `${convertIso8601DurationToReadable(
            offer.phases[0].duration
          )} free trial`
        : toReadable(offer.phases[0].type);
    phasesDescription = `${phase1Description} then ${offer.phases[1].type}`;
  }
  if (phasesDescription.length > 0)
    return `${offer.offer_id} • ${phasesDescription}`;
  return `${offer.offer_id}`;
}
export function getDescriptionForGoogleOfferPhase(
  phase: TGoogleProductOfferPhase
): string {
  if (phase.type === 'discounted recurring payment') {
    return `${toReadable(phase.type)} for ${
      phase.recurrenceCount
    } ${convertIso8601DurationToPeriod(phase.duration)}${
      phase.recurrenceCount > 1 ? 's' : ''
    }`;
  }
  return `${toReadable(phase.type)} for ${convertIso8601DurationToReadable(
    phase.duration
  )}`;
}

import React, { useContext } from 'react';

import { EllipsisOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Space, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'src/AppContextProvider';
import StatusTag from 'src/components/StatusTags/StatusTags';

type CampaignFooterButtonProps = {
  size: 'mdUp' | 'mdDown';
  duplicateAction: () => any;
  bulkDuplicateAction: () => any;
  actionButton: React.ReactNode;
  copyAction: () => any;
  archiveAction: () => void;
  currentlyArchived?: boolean;
  hasChanges: boolean;
};

export default function CampaignFooterButtons({
  size,
  duplicateAction,
  copyAction,
  actionButton,
  bulkDuplicateAction,
  archiveAction,
  currentlyArchived = false,
  hasChanges,
}: CampaignFooterButtonProps) {
  const { userHasEntitlement, planHasEntitlement } = useContext(AppContext);
  const canCreateCampaign = userHasEntitlement('app.campaign.create');
  const canUpdateCampaign = userHasEntitlement('app.campaign.update');
  const canBulkEditCampaigns = planHasEntitlement('app.campaign.bulk_update');
  const canCreateInfinitePlacements = planHasEntitlement(
    'app.placement.create_infinite'
  );
  const history = useHistory();
  let items: MenuProps['items'] = [
    {
      label: 'Duplicate',
      key: 'duplicate',
      onClick: () => {
        if (hasChanges) {
          notification.warn({ message: 'Save changes before duplicating' });
        } else {
          duplicateAction();
        }
      },
      disabled: !canCreateCampaign || currentlyArchived,
    },
    {
      label: 'Copy ID',
      key: 'copy',
      onClick: copyAction,
    },
    {
      label: currentlyArchived ? 'Restore' : 'Archive',
      key: 'archive',
      onClick: archiveAction,
      disabled: !canUpdateCampaign,
    },
  ];

  if (canCreateInfinitePlacements) {
    items.splice(1, 0, {
      label: 'Copy to Placements',
      icon: !canBulkEditCampaigns ? <LockOutlined /> : undefined,
      key: 'bulk-duplicate',
      onClick: bulkDuplicateAction,
      disabled:
        !canCreateCampaign || currentlyArchived || !canBulkEditCampaigns,
    });
  }
  if (size === 'mdDown')
    items.unshift({
      label: 'Discard Edits',
      key: 'discard',
      onClick: () => history.push('/campaigns'),
    });

  return (
    <Space direction="horizontal" size={12} style={{ float: 'right' }}>
      {hasChanges && size === 'mdUp' && (
        <StatusTag color="blue">Unpublished edits</StatusTag>
      )}
      <Dropdown
        menu={{ items: items }}
        placement={'topRight'}
        overlayStyle={{ width: 175 }}
      >
        <Button icon={<EllipsisOutlined />} />
      </Dropdown>
      {size === 'mdUp' && (
        <Button onClick={() => history.push('/campaigns/')}>
          Discard Edits
        </Button>
      )}
      {actionButton}
    </Space>
  );
}

import React from 'react';

import { ConfigProvider, Empty, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment-timezone';
import { useParams } from 'react-router-dom';
import {
  GoogleProductStateIcon,
  GoogleProductStateReadable,
  TGoogleProductOffer,
  TGoogleProductState,
} from 'src/api/types/sku.types';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import { useGoogleOffersForProductQuery } from 'src/hooks/queries/product.hooks';
import { getDescriptionForGoogleOfferPhase } from 'src/utils/sku';
import { namiDarkGray } from 'src/variables';

export default function GoogleOffersTable() {
  const productId = useParams<{ productID: string }>().productID;
  const googleOffersForProduct = useGoogleOffersForProductQuery(productId);

  const columns: ColumnsType<TGoogleProductOffer> = [
    {
      title: 'ID',
      dataIndex: 'offer_id',
      key: 'id',
      render: (offer_id: string) => <MonospaceText>{offer_id}</MonospaceText>,
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      render: (state: TGoogleProductState) => {
        return (
          <Space>
            {GoogleProductStateIcon[state]}
            <span>{GoogleProductStateReadable[state]}</span>
          </Space>
        );
      },
    },
    {
      title: 'Phases',
      key: 'phases',
      render: (offer: TGoogleProductOffer) => {
        const phaseDescriptions = getPhaseDescriptionsForOffer(offer);
        return phaseDescriptions.map((phase, index) => (
          <div key={`${offer.id}-phase${index}`}>{`#${
            index + 1
          } - ${phase}`}</div>
        ));
      },
    },
    {
      title: 'First Sync Date',
      key: 'created_date',
      dataIndex: 'created_date',
      render: (created_date: string) => (
        <Tooltip title={moment(created_date).format('YYYY-MM-DD h:mm A')}>
          <span>{moment(created_date).fromNow()}</span>
        </Tooltip>
      ),
      responsive: ['lg'],
    },
  ];

  return (
    <>
      <Typography.Title level={5} style={{ marginTop: '48px' }}>
        Offers
      </Typography.Title>
      <Typography.Paragraph>
        Offers provide discounted prices to eligible users. You can use these to
        encourage first-time subscribers and upgrade existing subscribers. Learn
        more in{' '}
        <a
          href="https://play.google.com/console/developers/"
          target="_blank"
          rel="noreferrer"
        >
          Google Play Console.
        </a>
      </Typography.Paragraph>
      <ConfigProvider
        renderEmpty={() => (
          <Empty imageStyle={{ display: 'none' }} description={<></>}>
            <span style={{ color: namiDarkGray, fontStyle: 'italic' }}>
              No offers found
            </span>
          </Empty>
        )}
      >
        <Table
          dataSource={googleOffersForProduct.data?.results || []}
          columns={columns}
          pagination={false}
          loading={googleOffersForProduct.isLoading}
          bordered={true}
          size="small"
          rowKey="id"
        />
      </ConfigProvider>
    </>
  );

  function getPhaseDescriptionsForOffer(offer: TGoogleProductOffer): string[] {
    return offer.phases.map((phase) =>
      getDescriptionForGoogleOfferPhase(phase)
    );
  }
}
